import React, { FC } from "react";
import Input from "@kamae-apps/shared/Component/Input/Input";
import clsx from "clsx";
import Select from "@kamae-apps/shared/Component/Input/Select";
import { LoginMethod } from "@kamae-apps/shared/Types/Company/LoginMethod";
import FileInput from "@kamae-apps/shared/Component/Input/FileInput";
import Checked from "../../../../Icons/Checked";
import UnChecked from "../../../../Icons/UnChecked";
import { OnboardingTasksKeys } from "@kamae-apps/shared/Types/Onboarding";
import Spinner from "@kamae-apps/shared/Component/Button/Spinner";
import CalendarInput from "@kamae-apps/shared/Component/Input/CalendarInput";
import Slider from "@kamae-apps/shared/Component/Input/Slider";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import { isValidDomain } from "../../../../../utils";
import { MailDomain } from "../CompanyCryptr";
import {
  InformationContainerProps,
  useInformationContainer,
} from "./useInformationContainer";
import { ConditionalRender } from "@kamae-apps/shared/Component/ConditionalRender/ConditionalRender";
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus";
import { NotificationsSection } from "../../Components/NotificationsSection";

export const InformationContainer: FC<InformationContainerProps> = ({
  company,
  setCompany,
  isNewCompany,
}) => {
  const {
    loading,
    valid,
    logo,
    setLogo,
    updateName,
    updateIDP,
    loginOptions,
    updateLoginMethod,
    defaultLanguage,
    updateLanguage,
    newDomain,
    addNewDomain,
    setNewDomain,
    domains,
    removeDomain,
    companyOnboarding,
    options,
    updateStatus,
    updateMaxUsers,
    updateMaxAdmins,
    updateStartLicenseDate,
    setIsLicenseStartDateValid,
    updateEndLicenseDate,
    setIsLicenseEndDateValid,
    globalModules,
    check,
    setCheck,
    updatePhishing,
    updateSSO,
    updateLMS,
    updateTeams,
    updateDirSync,
    saveCompany,
    updateAutomatedEmails,
  } = useInformationContainer(company, setCompany, isNewCompany);

  return (
    <div className={"h-full p-3"}>
      <form
        className={
          "relative mt-4 flex h-full w-full flex-col items-center gap-6"
        }
        onSubmit={(e) => e.preventDefault()}
      >
        <div className={"flex w-full flex-col items-center gap-2 p-2"}>
          <div className={"flex w-full gap-4"}>
            <div className={"flex w-1/2 flex-col items-center gap-6"}>
              <h3 className={"text-lg"}>Informations Entreprise</h3>
              <Input
                className={clsx(
                  "!w-full",
                  company.name !== "" ? "" : "border-2 border-red-500"
                )}
                disabled={loading}
                placeholder={"Nom"}
                value={company.name}
                placeholderColor={company.name !== "" ? "" : "text-red-500"}
                onChange={(e) => {
                  updateName(e.target.value);
                }}
              />
              <FileInput
                className={"!w-full"}
                placeHolder={"Logo"}
                value={logo}
                setValue={setLogo}
                disabled={loading}
              />
              <Select
                className={"!z-20 !w-full"}
                defaultValue={company.defaultLanguageId}
                options={defaultLanguage}
                placeholder={"Langue par défaut"}
                onChange={(e) => {
                  updateLanguage(parseInt(e.target.value));
                }}
                disabled={loading}
              />
              <Select
                className={" !w-full"}
                options={loginOptions}
                disabled={loading}
                placeholder={"Méthode de connexion"}
                defaultValue={company.loginMethod}
                onChange={(e) => {
                  updateLoginMethod(e.target.value as LoginMethod);
                }}
              />
              <ConditionalRender condition={!isNewCompany}>
                <Input
                  className={"!w-full"}
                  disabled={loading}
                  placeholder={"IDP"}
                  value={company.idp ?? ""}
                  onChange={(e) => {
                    updateIDP(e.target.value);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender
                condition={company.status === CompanyStatus.Discovery}
              >
                <Input
                  className={"!w-full"}
                  disabled={true}
                  placeholder={"External client ID"}
                  value={company.external_customer_id ?? ""}
                />
              </ConditionalRender>
              {isNewCompany &&
                company.loginMethod === LoginMethod.MagicLink && (
                  <div className={"w-full"}>
                    <div className={"flex w-full items-center gap-4"}>
                      <Input
                        onEnterPress={addNewDomain}
                        className={"!m-0 !w-full"}
                        placeholder={"Ajouter un domaine"}
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                      />
                      <PrimaryButton
                        className={"!mx-0"}
                        disabled={!isValidDomain(newDomain)}
                        onClick={addNewDomain}
                      >
                        Ajouter
                      </PrimaryButton>
                    </div>
                    <div className={"mt-2 flex w-full flex-wrap gap-3"}>
                      {domains === undefined
                        ? "Chargement"
                        : domains?.map((v) => (
                            <MailDomain
                              value={v}
                              key={v}
                              remove={(data) => {
                                removeDomain(data);
                              }}
                            />
                          ))}
                    </div>
                  </div>
                )}
              <div className={"flex w-full gap-20"}>
                {!isNewCompany && (
                  <div
                    className={
                      "flex flex-col items-center justify-evenly gap-4 border-l-2 border-gray-100 pl-3"
                    }
                  >
                    <h3 className={"mb-3 text-lg"}>Suivi Onboarding</h3>
                    {company.onboarding_completed ? (
                      <p>{company.name} a terminé sa phase d'onboarding</p>
                    ) : (
                      <div className={"grid grid-cols-3 grid-rows-2 gap-y-3"}>
                        {companyOnboarding ? (
                          <>
                            {Object.entries(companyOnboarding.tasks).map(
                              (task, index) => (
                                <div
                                  className={"flex gap-2 p-1"}
                                  key={index}
                                >
                                  {task[1] ? <Checked /> : <UnChecked />}
                                  <span>
                                    {getOnboardingTaskName(
                                      task[0] as OnboardingTasksKeys
                                    )}
                                  </span>
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <Spinner className={"text-primary"} />
                        )}
                      </div>
                    )}
                  </div>
                )}
                <NotificationsSection
                  company={company}
                  onAutomatedEmailChange={(e) => {
                    updateAutomatedEmails(e.target.checked);
                  }}
                />
              </div>
            </div>
            <div className={"flex w-1/2 flex-col items-center gap-6"}>
              <h3 className={"text-lg"}>Informations License</h3>
              <Select
                className={"!z-30 !w-full"}
                defaultValue={company.status}
                options={options}
                placeholder={"Status"}
                onChange={(e) => {
                  updateStatus(parseInt(e.target.value));
                }}
                disabled={loading}
              />
              <div className={"flex w-full justify-between"}>
                <Input
                  className={clsx(
                    "mx-1 !w-1/2",
                    company.max_users ? "" : "border-2 border-red-500"
                  )}
                  type={"number"}
                  disabled={loading}
                  placeholder={"Nombre d'utilisateurs max"}
                  placeholderColor={company.max_users ? "" : "text-red-500"}
                  value={company.max_users}
                  onChange={(e) => {
                    updateMaxUsers(parseInt(e.target.value));
                  }}
                />
                <Input
                  className={clsx(
                    "!w-1-2 mx-1",
                    company.max_admins ? "" : "border-2 border-red-500"
                  )}
                  type={"number"}
                  disabled={loading}
                  placeholder={"Nombre d'admins max"}
                  placeholderColor={company.max_admins ? "" : "text-red-500"}
                  value={company.max_admins}
                  onChange={(e) => {
                    updateMaxAdmins(parseInt(e.target.value));
                  }}
                />
              </div>
              <CalendarInput
                className={"z-20 w-full"}
                value={company.license_start_date}
                setValue={updateStartLicenseDate}
                disabled={loading}
                placeHolder={"Début de contrat"}
                setIsDateValid={setIsLicenseStartDateValid}
              />
              <CalendarInput
                className={"z-10 w-full"}
                value={company.license_end_date}
                setValue={updateEndLicenseDate}
                disabled={loading}
                placeHolder={"Fin de contrat"}
                setIsDateValid={setIsLicenseEndDateValid}
              />
              <div className={"flex w-full gap-20"}>
                <div
                  className={
                    "flex flex-col gap-4 border-l-2 border-gray-100 pl-3"
                  }
                >
                  <h3 className={"mb-2 w-full text-center text-lg"}>
                    Modules E-Learning
                  </h3>
                  {globalModules?.map((module, i) => (
                    <div
                      className={"flex justify-start"}
                      key={i}
                    >
                      <Slider
                        id={`module_${module.id}`}
                        className={"m-1"}
                        value={module.id}
                        checked={check[i]}
                        onChange={() => {
                          setCheck(
                            check.map((module, index) =>
                              i === index ? !module : module
                            )
                          );
                        }}
                      />
                      <label
                        htmlFor={`module_${module.id}`}
                        className={"flex grow items-center justify-between"}
                      >
                        <span className={"mr-2"}>{module.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
                <div
                  className={
                    "flex flex-col gap-4 border-l-2 border-gray-100 pl-3"
                  }
                >
                  <h3 className={"mb-2 w-full text-center text-lg"}>Options</h3>
                  <div className={"flex w-full justify-start"}>
                    <Slider
                      id={"companyHasPhishing"}
                      checked={company.phishing_enabled ?? false}
                      onChange={(e) => {
                        updatePhishing(e.target.checked);
                      }}
                      className={"m-1"}
                    />
                    <label
                      htmlFor={"companyHasPhishing"}
                      className={"flex grow items-center justify-between"}
                    >
                      <span className={"mr-2"}>Phishing</span>
                    </label>
                  </div>
                  <div className={"flex w-full justify-start"}>
                    <Slider
                      id={"companyHasSSO"}
                      checked={company.sso_enabled ?? false}
                      onChange={(e) => {
                        updateSSO(e.target.checked);
                      }}
                      className={"m-1"}
                    />
                    <label
                      htmlFor={"companyHasSSO"}
                      className={"flex grow items-center justify-between"}
                    >
                      <span className={"mr-2"}>SSO</span>
                    </label>
                  </div>
                  <div className={"flex"}>
                    <Slider
                      id={"companyHasDirSync"}
                      checked={company.dir_sync_enabled}
                      onChange={(e) => {
                        updateDirSync(e.target.checked);
                      }}
                      className={"m-1"}
                      disabled={!company.sso_enabled}
                    />
                    <label
                      htmlFor={"companyHasDirSync"}
                      className={clsx(
                        "flex items-center",
                        company.sso_enabled || "text-gray-300"
                      )}
                    >
                      <span>SCIM</span>
                    </label>
                  </div>
                  <div className={"flex w-full justify-start"}>
                    <Slider
                      id={"companyHasLMS"}
                      checked={company.lms_enabled ?? false}
                      onChange={(e) => {
                        updateLMS(e.target.checked);
                      }}
                      className={"m-1"}
                    />
                    <label
                      htmlFor={"companyHasLMS"}
                      className={"flex grow items-center justify-between"}
                    >
                      <span className={"mr-2"}>LMS</span>
                    </label>
                  </div>
                  <div className={"flex"}>
                    <Slider
                      id={"companyHasTeams"}
                      checked={company.teams_enabled}
                      onChange={(e) => {
                        updateTeams(e.target.checked);
                      }}
                      className={"m-1"}
                    />
                    <label
                      htmlFor={"companyHasTeams"}
                      className={"flex grow items-center justify-between"}
                    >
                      <span className={"mr-2"}>Microsoft Teams</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"flex"}>
          <PrimaryButton
            type={"submit"}
            disabled={!valid || loading}
            onClick={saveCompany}
            spinning={loading}
          >
            Sauvegarder
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

function getOnboardingTaskName(taskKey: OnboardingTasksKeys) {
  switch (taskKey) {
    case "email_whitelisted":
      return "Whitelister email";
    case "organization_customized":
      return "Personnaliser dojo";
    case "phishing_configured":
      return "Configurer phishing";
    case "sso_configured":
      return "Configurer SSO";
    case "users_imported":
      return "Importer collaborateurs";
    case "onboarding_email_sent":
      return "Envoyer invitations";
    case "scim_configured":
      return "Configurer SCIM";
    default:
      return "Tâche inconnue";
  }
}
