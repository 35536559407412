import Button from "@kamae-apps/shared/Component/Button/Button";
import FileInput from "@kamae-apps/shared/Component/Input/FileInput";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import {
  errorToast,
  successToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";
import config from "@kamae-apps/shared/variable";
import { useState } from "react";

export default function CompanyImport() {
  const toast = useToast();
  const [csv, setCSV] = useState<File | null>(null);

  return (
    <div className="flag-gap-1 m-8 flex justify-center">
      <FileInput setValue={setCSV}></FileInput>
      <Button
        className={
          "border-primary text-primary hover:bg-primary disabled:border-primary-200 disabled:text-primary-200 mx-4 px-8 py-2 transition-colors hover:text-white disabled:bg-white"
        }
        onClick={() => {
          const headers = new Headers();
          const jwt = localStorage.getItem("jwt") ?? "";
          headers.append("Authorization", "Bearer " + jwt);
          headers.append("Content-Type", "text/csv");
          fetch(config.api + "/companies/batch", {
            method: "POST",
            body: csv,
            headers,
          })
            .then(() => {
              toast.addToast(
                successToast(
                  "Demande de création d'entreprises prise en compte"
                )
              );
            })
            .catch((err) => {
              toast.addToast(errorToast("Erreur inconnue :\n" + err));
            });
        }}
      >
        Valider
      </Button>
    </div>
  );
}
