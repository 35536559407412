import { CompanyData } from "../../../Companies";
import useReload from "@kamae-apps/shared/Hooks/useReload";
import { useNavigate } from "react-router-dom";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import { Module } from "@kamae-apps/shared/Types/Module";
import { OnboardingState } from "@kamae-apps/shared/Types/Onboarding";
import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus";
import { OptionInterface } from "@kamae-apps/shared/Component/Input/Select";
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus";
import { LoginMethod } from "@kamae-apps/shared/Types/Company/LoginMethod";
import { DefaultLanguage } from "@kamae-apps/shared/Types/Company/DefaultLanguage";
import {
  errorToast,
  successToast,
  warningToast,
} from "@kamae-apps/shared/Component/Toast/ToastBuilder";
import { isValidDomain } from "../../../../../utils";
import { NewCompany } from "../../../../../Types/TUser";
import {
  apiRequest,
  apiRequestRaw,
  dateToISODate,
} from "@kamae-apps/shared/utils";
import { TCompany } from "@kamae-apps/shared/Types/Company/TCompany";
import { APIError } from "@kamae-apps/shared/Types/API";

export type InformationContainerProps = {
  company: CompanyData;
  setCompany: (value: CompanyData) => void;
  isNewCompany?: boolean;
};
export const useInformationContainer = (
  company: CompanyData,
  setCompany: (value: CompanyData) => void,
  isNewCompany?: boolean
) => {
  const { reload, doReload } = useReload();
  const navigate = useNavigate();
  const toast = useToast();
  const [logo, setLogo] = useState<File | null>(null);
  const globalModules = useApiRequest<Module[]>("/module");
  const companyModules = useApiRequest<Module[]>(
    `/module/${company.id}`,
    [reload],
    { if: !isNewCompany }
  );
  const [check, setCheck] = useState<boolean[]>([false, false, false]);
  const companyOnboarding = useApiRequest<OnboardingState>(
    `/company/${company.id}/onboarding`,
    [reload],
    { if: !isNewCompany }
  );
  const [isLicenseStartDateValid, setIsLicenseStartDateValid] = useState(true);
  const [isLicenseEndDateValid, setIsLicenseEndDateValid] = useState(true);
  const [domains, setDomains] = useState<string[]>([]);
  const [newDomain, setNewDomain] = useState("");

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const { code } = useApiStatus();

  const options: OptionInterface[] = useMemo(() => {
    return Object.entries(CompanyStatus)
      .filter(([, v]) => typeof v === "number")
      .map(([k, v]) => {
        return { name: k, value: v };
      });
  }, []);

  const loginOptions: OptionInterface[] = useMemo(() => {
    return Object.entries(LoginMethod)
      .filter(
        (v) => (isNewCompany && v[1] !== LoginMethod.SSO) || !isNewCompany
      )
      .map(([k, v]) => {
        return { name: k, value: v };
      });
  }, [isNewCompany]);

  const defaultLanguage: OptionInterface[] = useMemo(() => {
    return Object.entries(DefaultLanguage)
      .filter(([, v]) => typeof v === "number")
      .map(([k, v]) => {
        return { name: k, value: v };
      });
  }, []);

  const updateName = (name: string) => {
    const tmp = Object.assign({}, company);
    tmp.name = name;
    setCompany(tmp);
  };

  const updateLanguage = (language: number) => {
    const tmp = Object.assign({}, company);
    tmp.defaultLanguageId = language;
    setCompany(tmp);
  };

  const updateLoginMethod = (loginMethod: LoginMethod) => {
    const tmp = Object.assign({}, company);
    tmp.loginMethod = loginMethod;
    setCompany(tmp);
  };

  const addNewDomain = () => {
    if (isValidDomain(newDomain)) {
      setDomains(domains.concat(newDomain));
      setNewDomain("");
    }
  };

  const removeDomain = (domain: string) => {
    setDomains(domains.filter((v) => v !== domain));
  };

  const updateIDP = useCallback(
    (idp: string) => {
      const tmp = Object.assign({}, company);
      if (idp === "") {
        tmp.idp = undefined;
      } else {
        tmp.idp = idp;
      }
      setCompany(tmp);
    },
    [company, setCompany]
  );

  const updateStatus = (status: number) => {
    const tmp = Object.assign({}, company);
    tmp.status = status;
    setCompany(tmp);
  };

  const updateMaxUsers = (maxUsers: number) => {
    const tmp = Object.assign({}, company);
    tmp.max_users = maxUsers;
    setCompany(tmp);
  };

  const updateMaxAdmins = (maxAdmins: number) => {
    const tmp = Object.assign({}, company);
    tmp.max_admins = maxAdmins;
    setCompany(tmp);
  };

  const updateStartLicenseDate = (date: Date) => {
    const tmp = Object.assign({}, company);
    tmp.license_start_date = date;
    setCompany(tmp);
  };

  const updateEndLicenseDate = (date: Date) => {
    const tmp = Object.assign({}, company);
    tmp.license_end_date = date;
    setCompany(tmp);
  };

  const updatePhishing = (isEnabled: boolean) => {
    const tmp = Object.assign({}, company);
    tmp.phishing_enabled = isEnabled;
    setCompany(tmp);
  };

  const updateAutomatedEmails = (isEnabled: boolean) => {
    const tmp = Object.assign({}, company);
    tmp.automated_email_enabled = isEnabled;
    setCompany(tmp);
  };

  const updateSSO = (isEnabled: boolean) => {
    const tmp = Object.assign({}, company);
    tmp.sso_enabled = isEnabled;
    if (!isEnabled) {
      tmp.dir_sync_enabled = false;
    }
    setCompany(tmp);
  };

  const updateLMS = (isEnabled: boolean) => {
    const tmp = Object.assign({}, company);
    tmp.lms_enabled = isEnabled;
    setCompany(tmp);
  };

  const updateDirSync = (isEnabled: boolean) => {
    const tmp = Object.assign({}, company);
    tmp.dir_sync_enabled = isEnabled;
    setCompany(tmp);
  };

  const updateTeams = (isEnabled: boolean) => {
    const tmp = Object.assign({}, company);
    tmp.teams_enabled = isEnabled;
    setCompany(tmp);
  };

  const saveCompany = () => {
    setLoading(true);
    if (isNewCompany) {
      const companyModulesId = companyModules?.map((module) => module.id) ?? [];
      const data: NewCompany = {
        name: company.name,
        idp: company.idp,
        login_method: company.loginMethod,
        status: company.status,
        license_start_date: dateToISODate(company.license_start_date),
        license_end_date: dateToISODate(company.license_end_date),
        max_users: company.max_users,
        max_admins: company.max_admins,
        phishing_enabled: company.phishing_enabled,
        sso_enabled: company.sso_enabled,
        lms_enabled: company.lms_enabled,
        companyModules: check
          .map((module, i) => (module ? globalModules!![i].id : -1))
          .filter(
            (moduleID) =>
              moduleID !== -1 && !companyModulesId.includes(moduleID)
          ),
        defaultLanguageId: company.defaultLanguageId,
        dir_sync_enabled: company.dir_sync_enabled,
        domains: domains,
        automated_email_enabled: company.automated_email_enabled,
      };
      apiRequest<TCompany>("/company", { method: "POST", body: data })
        .then((res) => {
          if (logo !== null) {
            const fr = new FileReader();
            fr.onloadend = function () {
              setLoading(false);
              apiRequestRaw("/company/" + res?.id + "/logo", "image/png", {
                method: "PUT",
                body: fr.result ?? "",
              })
                .then(() => {})
                .catch((err) => {
                  if (err instanceof APIError && err.code === 409) {
                    toast.addToast(warningToast("Le fichier existe déjà"));
                  }
                });
            };
            fr.readAsArrayBuffer(logo);
          } else {
            setLoading(false);
          }
          toast.addToast(successToast("Entreprise ajouté"));
          navigate(`/companies/${res?.id}`);
        })
        .catch(() => {
          setLoading(false);
          toast.addToast(errorToast("Erreur lors de l'ajout de l'entreprise"));
        });
    } else {
      const companyModulesId = companyModules?.map((module) => module.id) ?? [];
      const newModules = check
        .map((module, i) => (module ? globalModules!![i].id : -1))
        .filter(
          (moduleID) => moduleID !== -1 && !companyModulesId.includes(moduleID)
        );
      const data = {
        name: company.name,
        idp: company.idp,
        login_method: company.loginMethod,
        status: company.status,
        license_start_date: dateToISODate(company.license_start_date),
        license_end_date: dateToISODate(company.license_end_date),
        max_users: company.max_users,
        max_admins: company.max_admins,
        phishing_enabled: company.phishing_enabled,
        sso_enabled: company.sso_enabled,
        lms_enabled: company.lms_enabled,
        newCompanyModules: newModules,
        activeCompanyModules: companyModulesId,
        defaultLanguageId: company.defaultLanguageId,
        dir_sync_enabled: company.dir_sync_enabled,
        teams_enabled: company.teams_enabled,
        automated_email_enabled: company.automated_email_enabled,
      };
      apiRequest<TCompany>("/company/" + company.id, {
        method: "PUT",
        body: data,
      })
        .then((resp) => {
          const tmp = Object.assign({}, company);
          tmp.onboarding_completed = resp?.onboarding_completed ?? true;
          setCompany(tmp);
          const companyModulesId =
            companyModules?.map((module) => module.id) ?? [];
          apiRequest("/module/" + company.id, {
            method: "POST",
            body: check
              .map((module, i) => (module ? globalModules!![i].id : -1))
              .filter(
                (moduleID) =>
                  moduleID !== -1 && !companyModulesId.includes(moduleID)
              ),
          }).then(() => {
            globalModules
              ?.filter(
                (module, i) => !check[i] && companyModulesId.includes(module.id)
              )
              .forEach((module) => {
                apiRequest("/module/" + company.id + "/" + module.id, {
                  method: "DELETE",
                });
              });
            if (logo !== null) {
              const fr = new FileReader();
              fr.onloadend = function () {
                apiRequestRaw("/company/" + company.id + "/logo", "image/png", {
                  method: "PUT",
                  body: fr.result ?? "",
                })
                  .then(() => {
                    setLoading(false);
                    toast.addToast(successToast("Entreprise mise à jour"));
                  })
                  .catch((err) => {
                    if (err instanceof APIError && err.code === 409) {
                      setLoading(false);
                      toast.addToast(warningToast("Le fichier existe déjà"));
                    }
                  });
              };
              fr.readAsArrayBuffer(logo);
            } else {
              setLoading(false);
              toast.addToast(successToast("Entreprise mise à jour"));
            }
            doReload();
          });
        })
        .catch(() => {
          setLoading(false);
          toast.addToast(
            errorToast("Erreur lors de la mis à jour de l'entreprise")
          );
        });
    }
  };

  const isCompanyValid = useCallback(() => {
    if (company.name === "") return false;
    if (!isLicenseStartDateValid) return false;
    if (!isLicenseEndDateValid) return false;
    if (company.max_users <= 0) return false;
    if (
      isNewCompany &&
      company.loginMethod === LoginMethod.MagicLink &&
      domains.length === 0
    )
      return false;
    return true;
  }, [
    company.loginMethod,
    company.max_users,
    company.name,
    domains.length,
    isLicenseStartDateValid,
    isLicenseEndDateValid,
    isNewCompany,
  ]);

  useEffect(() => {
    const companyModuleId = companyModules?.map((v) => v.id) ?? [];
    if (globalModules !== undefined) {
      setCheck(globalModules.map((v) => companyModuleId.includes(v.id)));
    }
  }, [companyModules, globalModules]);

  useEffect(() => {
    setValid(isCompanyValid());
  }, [isCompanyValid]);

  useEffect(() => {
    if (isNewCompany) {
      if (
        company.loginMethod === LoginMethod.Password &&
        company.idp !== "auth0"
      ) {
        updateIDP("auth0");
      } else if (
        company.loginMethod !== LoginMethod.Password &&
        company.idp !== undefined
      ) {
        updateIDP("");
      }
    }
  }, [isNewCompany, company.loginMethod, company.idp, updateIDP]);

  useEffect(() => {
    if (code === 206) {
      toast.addToast(
        warningToast("La connexion magic link n'a pas pu être créée")
      );
    }
  }, [toast, code]);

  return {
    loading,
    valid,
    logo,
    setLogo,
    updateName,
    updateIDP,
    loginOptions,
    updateLoginMethod,
    defaultLanguage,
    updateLanguage,
    newDomain,
    setNewDomain,
    addNewDomain,
    domains,
    removeDomain,
    companyOnboarding,
    options,
    updateStatus,
    updateMaxUsers,
    updateMaxAdmins,
    updateStartLicenseDate,
    setIsLicenseStartDateValid,
    updateEndLicenseDate,
    setIsLicenseEndDateValid,
    globalModules,
    check,
    setCheck,
    updatePhishing,
    updateSSO,
    updateLMS,
    updateDirSync,
    saveCompany,
    updateTeams,
    updateAutomatedEmails,
  };
};
